<template>
  <div id="inspire">
    <div class="icon-wrapper cursor-pointer" @click="telecharger">
      <b-button block variant="secondary" size="lg" class="ml-2 text-uppercase">
        Télécharger
      </b-button>
    </div>
    <div id="printDiv">
      <b-row>
        <b-col cols="6" class="br invoice-box parent">
          <div class="child1">
            <img class="px-0 img" src="@/assets/original-f.svg" />
          </div>
          <div>
            <div id="vue_globale" class="mt-2">
              <div class="scope1" id="scope1">
                <div>
                  <img class="logo" :src="entete.logo" />
                </div>
                <div class="ml-auto">
                  <div>
                    {{ entete.name }}
                  </div>
                  <div>
                    {{ entete.telephone }}
                  </div>
                  <div>
                    {{ entete.ifu }}
                  </div>
                </div>
              </div>

              <hr v-if="entete.name" />
              <div v-else class="py-1"></div>
              <div
                id="texte facturetitle"
                class="text-decoration-underline font-weight-medium text-center"
              >
                Facture Proforma n°
                {{ facture.pro_facture_no }}
              </div>
              <div id="texte" class="text-right">
                <span class="text-decoration-underline font-weight-medium"
                  >DATE:</span
                >
                {{ date }}
              </div>
              <div id="texte" class="text-right">
                <span class="text-decoration-underline font-weight-medium"
                  >OPERATEUR:</span
                >
                {{ user.name }}
              </div>

              <!-- INFORMATIONS DU CLIENT -->
              <div class="d-flex flex-column">
                <div id="texte" class="">
                  Mr / Mme {{ signataire.name }} reconnaît avoir fait la facture proforma à :
                </div>
                <div>
                  <b-table-simple class="tableau align-center">
                    <b-thead>
                      <b-tr class="font-weight-black">
                        <b-th class="">Nom et Prénom(s)</b-th>
                        <b-th class="">Téléphone</b-th>
                        <b-th class="">IFU</b-th>
                        <b-th class="">RECCM</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr>
                        <b-td class="">{{ client.name }}</b-td>
                        <b-td class="">{{ client.phone }}</b-td>
                        <b-td class="">{{ client.ifu }}</b-td>
                        <b-td class="">{{ client.reccm }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </div>
              </div>

              <!-- INFORMATIONS DU VEHICULE -->
              <div class="d-flex flex-column">
                <div id="texte" class="">Le véhicule :</div>
                <div>
                  <b-table-simple class="tableau align-center">
                    <b-thead>
                      <b-tr class="font-weight-black">
                        <b-th class="">N° Chassis</b-th>
                        <b-th class="">Marque</b-th>
                        <b-th class="">Année</b-th>
                        <b-th class="">Couleur</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr>
                        <b-td class="">{{ vehicule.numero_chassis }}</b-td>
                        <b-td class="">{{ vehicule.marque }}</b-td>
                        <b-td class="">{{ vehicule.annee }}</b-td>
                        <b-td class="">{{ vehicule.couleur }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                  <div class="py-2" id="texte">
                    Pour la somme de :
                    <span class="font-weight-black">
                      {{ numToWords(montant_vente) }} ({{
                        $thousandSeparator(montant_vente)
                      }}) {{ vehicule.dev_vente }} </span
                    >, Avance payée :
                    <span class="font-weight-black">
                      {{ numToWords(total_paye) }} ({{
                        $thousandSeparator(facture.total_paye)
                      }}) {{ vehicule.dev_vente }}</span
                    >, et un reste à payer de
                    <span class="font-weight-black">
                      {{ numToWords(reste_a_paye) }} ({{
                        $thousandSeparator(facture.reste_a_paye)
                      }}) {{ vehicule.dev_vente }}</span
                    >
                  </div>
                </div>
              </div>

              <!-- INFORMATIONS DU PAIEMENT -->
              <div class="d-flex flex-column">
                <div>
                  <b-table-simple class="tableau align-center">
                    <b-thead>
                      <b-tr class="font-weight-black">
                        <b-th colspan="4" class="text-center"
                          >DETAILS PAIEMENT</b-th
                        >
                      </b-tr>
                      <b-tr class="font-weight-black">
                        <b-th class="">Montant de la vente</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr>
                        <b-td class="">
                          {{ $thousandSeparator(facture.montant_vente) }}
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </div>
              </div>

              <!-- SIGNATURES -->
              <div class="d-flex justify-space-between mb-5">
                <div class="text-decoration-underline">
                  {{ facture.deposant ? "Le déposant" : "Le client" }}
                </div>
                <div class="text-decoration-underline mb-12 ml-auto">
                  L'opérateur
                </div>
              </div>

              <div class="d-flex mb-1 justify-space-between">
                <div id="texte" class="">
                  {{ client.name }}
                </div>
                <div id="texte" class="ml-auto">
                  {{ signataire.name }}
                </div>
              </div>
            </div>
            <div
              class="text-right"
              style="bottom: 0; font-size: 10px; opacity: 0.5"
            >
              Edité par FlashCar
            </div>
          </div>
        </b-col>
        <b-col cols="6" class="bl invoice-box parent">
          <div class="child1">
            <img class="px-0 img" src="@/assets/copie-f.svg" />
          </div>
          <div>
            <div id="vue_globale" class="mt-2">
              <div class="scope1" id="scope1">
                <div>
                  <img class="logo" :src="entete.logo" />
                </div>
                <div class="ml-auto">
                  <div>
                    {{ entete.name }}
                  </div>
                  <div>
                    {{ entete.telephone }}
                  </div>
                  <div>
                    {{ entete.ifu }}
                  </div>
                </div>
              </div>

              <hr v-if="entete.name" />
              <div v-else class="py-1"></div>
              <div
                id="texte facturetitle"
                class="text-decoration-underline font-weight-medium text-center"
              >
                Facture Proforma n°
                {{ facture.pro_facture_no }}
              </div>
              <div id="texte" class="text-right">
                <span class="text-decoration-underline font-weight-medium"
                  >DATE:</span
                >
                {{ date }}
              </div>
              <div id="texte" class="text-right">
                <span class="text-decoration-underline font-weight-medium"
                  >OPERATEUR:</span
                >
                {{ user.name }}
              </div>

              <!-- INFORMATIONS DU CLIENT -->
              <div class="d-flex flex-column">
                <div id="texte" class="">
                  Mr / Mme {{ signataire.name }} reconnaît avoir vendu à :
                </div>
                <div>
                  <b-table-simple class="tableau align-center">
                    <b-thead>
                      <b-tr class="font-weight-black">
                        <b-th class="">Nom et Prénom(s)</b-th>
                        <b-th class="">Téléphone</b-th>
                        <b-th class="">IFU</b-th>
                        <b-th class="">RECCM</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr>
                        <b-td class="">{{ client.name }}</b-td>
                        <b-td class="">{{ client.phone }}</b-td>
                        <b-td class="">{{ client.ifu }}</b-td>
                        <b-td class="">{{ client.reccm }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </div>
              </div>

              <!-- INFORMATIONS DU VEHICULE -->
              <div class="d-flex flex-column">
                <div id="texte" class="">Le véhicule :</div>
                <div>
                  <b-table-simple class="tableau align-center">
                    <b-thead>
                      <b-tr class="font-weight-black">
                        <b-th class="">N° Chassis</b-th>
                        <b-th class="">Marque</b-th>
                        <b-th class="">Année</b-th>
                        <b-th class="">Couleur</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr>
                        <b-td class="">{{ vehicule.numero_chassis }}</b-td>
                        <b-td class="">{{ vehicule.marque }}</b-td>
                        <b-td class="">{{ vehicule.annee }}</b-td>
                        <b-td class="">{{ vehicule.couleur }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                  <div class="py-2" id="texte">
                    Pour la somme de :
                    <span class="font-weight-black">
                      {{ numToWords(montant_vente) }} ({{
                        $thousandSeparator(montant_vente)
                      }}) {{ vehicule.dev_vente }} </span
                    >, Avance payée :
                    <span class="font-weight-black">
                      {{ numToWords(total_paye) }} ({{
                        $thousandSeparator(facture.total_paye)
                      }}) {{ vehicule.dev_vente }}</span
                    >, et un reste à payer de
                    <span class="font-weight-black">
                      {{ numToWords(reste_a_paye) }} ({{
                        $thousandSeparator(facture.reste_a_paye)
                      }}) {{ vehicule.dev_vente }}</span
                    >
                  </div>
                </div>
              </div>

              <!-- INFORMATIONS DU PAIEMENT -->
              <div class="d-flex flex-column">
                <div>
                  <b-table-simple class="tableau align-center">
                    <b-thead>
                      <b-tr class="font-weight-black">
                        <b-th colspan="4" class="text-center"
                          >DETAILS PAIEMENT</b-th
                        >
                      </b-tr>
                      <b-tr class="font-weight-black">
                        <b-th class="">Montant de la vente</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr>
                        <b-td class="">
                          {{ $thousandSeparator(facture.montant_vente) }}
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </div>
              </div>

              <!-- SIGNATURES -->
              <div class="d-flex justify-space-between mb-5">
                <div class="text-decoration-underline">
                  {{ facture.deposant ? "Le déposant" : "Le client" }}
                </div>
                <div class="text-decoration-underline mb-12 ml-auto">
                  L'opérateur
                </div>
              </div>

              <div class="d-flex mb-1 justify-space-between">
                <div id="texte" class="">
                  {{ client.name }}
                </div>
                <div id="texte" class="ml-auto">
                  {{ signataire.name }}
                </div>
              </div>
            </div>
            <div
              class="text-right"
              style="bottom: 0; font-size: 10px; opacity: 0.5"
            >
              Edité par FlashCar
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import EmptyList from "@core/components/empty-list/EmptyList.vue";
import {
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BPagination,
  BBadge,
  BFormSelect,
  BFormCheckbox,
  BRow,
  BCol,
  BContainer,
  BTable,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, alpha, alphaDash } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BAlert, BLink } from "bootstrap-vue";
import VueQr from "vue-qr";

export default {
  components: {
    BCardCode,
    BButton,
    BModal,
    BForm,
    EmptyList,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormCheckbox,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BRow,
    BCol,
    BBadge,
    BContainer,
    vSelect,
    BTable,
    ValidationProvider,
    ValidationObserver,
    BAlert,
    BLink,
    VueQr,
  },
  data() {
    return {
      isOperating: false,
      vehicule: {},
      societe: {},
      signataire: {},
      facture: {},
      client: {},
      entete: {},
      solderA: "",
      loaded: false,
      itemsClient: [],
      itemsVehicule: [],
      itemsPaiement: [],
      fieldsClient: [
        { key: "name", label: "Nom et Prénom(s)" },
        { key: "phone", label: "Téléphone" },
        { key: "ifu", label: "Ifu" },
        { key: "reccm", label: "Reccm" },
      ],
      fieldsVehicule: [
        { key: "numero_chassis", label: "N° Chassis" },
        { key: "marque", label: "Marque" },
        { key: "annee", label: "Année" },
        { key: "couleur", label: "Couleur" },
      ],
      fieldsPaiement: [{ key: "montant_vente", label: "DETAILS PAIEMENT" }],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.auth,
    }),
    codeqrtexte() {
      if (this.facture && this.facture.mcf_response) {
        let fact = JSON.parse(this.facture.mcf_response);
        let toReturn =
          "F;" + fact.nim + ";" + fact.sig + ";" + fact.ifu + ";" + fact.dt;
        return toReturn;
      } else {
        return "";
      }
      // {F};{NIM};{SIG};{IFU};{DT}
    },

    date() {
      var today = new Date();
      let annee = today.getFullYear();
      let mois = ("0" + (today.getMonth() + 1)).slice(-2);
      let jour = ("0" + today.getDate()).slice(-2);
      var date = jour + "/" + mois + "/" + annee;

      return date;
    },

    montant_vente() {
      return this.facture.montant_vente ? this.facture.montant_vente : 0;
    },

    total_paye() {
      return this.facture.total_paye ? this.facture.total_paye : 0;
    },

    reste_a_paye() {
      return this.facture.reste_a_paye ? this.facture.reste_a_paye : 0;
    },
  },
  created() {
    this.vehiculeData();
  },
  methods: {
    formatedDate(fact) {
      let invoicedt = fact ? JSON.parse(fact).dt : "000000000000";
      let date =
        invoicedt.substring(6, 8) +
        "/" +
        invoicedt.substring(4, 6) +
        "/" +
        invoicedt.substring(0, 4);

      return (
        date +
        " " +
        invoicedt.substring(8, 10) +
        ":" +
        invoicedt.substring(10, 12) +
        ":" +
        invoicedt.substring(12, 14)
      );
      // return invoicedt.substring(6, 8)/invoicedt.substring(4, 6)/invoicedt.substring(0, 4)+""+invoicedt.substring(8, 10)+":"+invoicedt.substring(10, 12)+":"+invoicedt.substring(12, 14)
    },
    qrcode(url, id) {
      // console.log(url, id)
      // console.log("QRCode chargé");
    },

    vehiculeData() {
      let id = this.$router.currentRoute.params.id;
      this.$http.get("/pro-factures/" + id).then((response) => {
        this.facture = response.data.data;
        this.vehicule = this.facture.vehicule;
        this.client = this.facture.client;
        this.itemsClient.push(this.client);
        this.itemsVehicule.push(this.vehicule);
        this.itemsPaiement.push({ montant_vente: this.facture.montant_vente });
        this.signataire = this.facture.signataire
          ? this.facture.signataire
          : {};
        this.$http.get("/societes").then((response) => {
          this.entete = response.data.data[0];
          this.entete = response.data.data[0];
        });
      });
    },

    telecharger() {
      // window.print()
      var printContents = document.getElementById("printDiv").innerHTML;
      var originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      window.onafterprint = window.close();
    },

    numToWords(n) {
        let a = [
          '', 'un', 'deux', 'trois', 'quatre',
          'cinq', 'six', 'sept', 'huit', 'neuf',
          'dix', 'onze', 'douze', 'treize', 'quartorze',
          'quinze', 'seize', 'dix-sept', 'dix-huit', 'dix-neuf'
        ];
        let b = [
          '', '', 'vingt', 'trente', 'quarante',
          'cinquante', 'soixante', 'soixante-dix', 'quatre-vingt', 'quatre-vingt-dix'
        ];
        let g = [
          '', 'mille', 'million', 'milliard', 'trilliard', 'quadrilliard',
          'quintilliard', 'sextilliard', 'septilliard', 'octilliard', 'nonilliard'
        ];

        const arr = x => Array.from(x);
        const num = x => Number(x) || 0;
        const str = x => String(x);
        const isEmpty = xs => xs.length === 0;
        const take = n => xs => xs.slice(0,n);
        const drop = n => xs => xs.slice(n);
        const reverse = xs => xs.slice(0).reverse();
        const comp = f => g => x => f (g (x));
        const not = x => !x;
        const chunk = n => xs =>
          isEmpty(xs) ? [] : [take(n)(xs), ...chunk (n) (drop (n) (xs))];
          
        // this part is really nasty still
        // it might edit this again later to show how Monoids could fix this up
        let makeGroup = ([ones,tens,huns]) => {
          return [
            num(huns) === 0 ? '' : num(huns) === 1 ? 'cent ': a[huns] + ' cent ',
            num(ones) === 0 ? b[tens] : b[tens] && b[tens] + '-' || '',
            a[tens+ones] || a[ones]
          ].join('');
        };

        // "thousands" constructor; no real good names for this, i guess
        let thousand = (group, i) => group === 'un' && g[i] === 'mille' ? g[i]: `${group} ${g[i]}`;
        
        // execute !
        if (typeof n === 'number') return this.numToWords(String(n));
        if (n === '0')             return 'zéro';
        return (comp (chunk(3)) (reverse) (arr(n)))
          .map(makeGroup)
          .map(thousand)
          .filter(comp(not)(isEmpty))
          .reverse()
          .join(' ')
          .trim();
    },

    convertNumberToWords(n, custom_join_character) {
      //     n = 1580000;
      var string = n.toString(),
        units,
        tens,
        scales,
        start,
        end,
        chunks,
        chunksLen,
        chunk,
        ints,
        i,
        word,
        words;

      // var and = custom_join_character || "et";
      var and = custom_join_character || "";

      /* Is number zero? */
      if (parseInt(string) === 0) {
        return "zero";
      }

      /* Array of units as words */
      units = [
        "",
        "un",
        "deux",
        "trois",
        "quatre",
        "cinq",
        "six",
        "sept",
        "huit",
        "neuf",
        "dix",
        "onze",
        "douze",
        "treize",
        "quatorze",
        "quinze",
        "seize",
        "dix-sept",
        "dix-huit",
        "dix-neuf",
      ];

      /* Array of tens as words */
      tens = [
        "",
        "",
        "vingt",
        "trente",
        "quarante",
        "cinquante",
        "soixante",
        "soixante-dix",
        "quatre-vingt",
        "quatre-vingt-dix",
      ];

      /* Array of scales as words */
      scales = [
        "",
        "mille",
        "million",
        "milliard",
        "trillion",
        "quadrillion",
        "quintillion",
        "sextillion",
        "septillion",
        "octillion",
        "nonillion",
        "decillion",
        "undecillion",
        "duodecillion",
        "tredecillion",
        "quatttuor-decillion",
        "quindecillion",
        "sexdecillion",
        "septen-decillion",
        "octodecillion",
        "novemdecillion",
        "vigintillion",
        "centillion",
      ];

      /* Split user arguemnt into 3 digit chunks from right to left */
      start = string.length;
      chunks = [];
      while (start > 0) {
        end = start;
        chunks.push(string.slice((start = Math.max(0, start - 3)), end));
      }

      /* Check if function has enough scale words to be able to stringify the user argument */
      chunksLen = chunks.length;
      if (chunksLen > scales.length) {
        return "";
      }

      /* Stringify each integer in each chunk */
      words = [];
      for (i = 0; i < chunksLen; i++) {
        chunk = parseInt(chunks[i]);

        if (chunk) {
          /* Split chunk into array of individual integers */
          ints = chunks[i].split("").reverse().map(parseFloat);

          /* If tens integer is 1, i.e. 10, then add 10 to units integer */
          if (ints[1] === 1) {
            ints[0] += 10;
          }

          /* Add scale word if chunk is not zero and array item exists */
          if ((word = scales[i])) {
            words.push(word);
          }

          /* Add unit word if array item exists */
          if ((word = units[ints[0]])) {
            words.push(word);
          }

          /* Add tens word if array item exists */
          if ((word = tens[ints[1]])) {
            words.push(word);
          }

          /* Add 'and' string after units or tens integer if: */
          if (ints[0] || ints[1]) {
            /* Chunk has a hundreds integer or chunk is the first of multiple chunks */
            if (ints[2] || (!i && chunksLen)) {
              words.push(and);
            }
          }

          /* Add hundreds word if array item exists */
          if ((word = units[ints[2]])) {
            words.push(word + " cent");
          }
        }
      }

      return words.reverse().join(" ");
    },
  },
};
</script>



<style lang="scss">
[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: #f8f8f8 !important;
}
.table {
  color: #111;
  border: 1px solid black;
}
[dir] .table th,
[dir] .table td,
[dir] .table thead th {
  border: 1px solid black;
}
#title1 {
  font-size: 20px;
  font-weight: 400;
}

#facturetitle {
  font-size: 30px;
}

@page {
  size: landscape;
}

.br {
  border-right: 1.2px solid rgb(27, 26, 26) !important;
}
.bl {
  border-left: 1.2px solid rgb(27, 26, 26) !important;
}

#scope1 {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 10px 0 4px 0;
  display: flex;
  justify-content: space-between;
}

#tableau_avertissement .v-data-table__wrapper table b-tr b-td {
  font-size: 0.8em !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

#societe {
  padding: 0px !important;
  margin: 0px !important;
}

.col-3,
.col-9 {
  padding: 0px !important;
}

#vue_globale {
  width: 100% !important;
}

.logo {
  /* min-width: 150px !important;
  max-width: 150px !important; */

  width: 40%;
  max-width: 150px;
  height: auto;
}

.qrcode {
  width: 80%;
  max-width: 150px;
  height: auto;
}

.v-application p {
  padding: 0px !important;
  margin: 0px !important;
}

#texte {
  font-size: 12px !important;
}

.invoice-box {
  // max-width: 800px;
  // font-size: 16px;
  // line-height: 13px;
  color: rgb(0, 0, 0);
}

@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}

.parent {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  .child1 {
    position: absolute;
    .img {
      height: 90vh;
      opacity: 0.2;
      width: 100% !important;
      overflow: hidden !important;
    }
  }

  .child2 {
    right: 0;
    margin-top: 10px !important;
    padding-right: 20px !important;
    font-size: 1.5em;
    font-weight: bold;
  }

  .child3 {
    top: 100px;
    z-index: 1;
    font-size: 4em !important;
    // margin: auto 50px !important;
    border-radius: 10px;
  }
}
</style>
